import { FC } from "react";
import css from './index.module.css';

interface Popup {
  children: any,
  showPopup: boolean, 
  setShowPopup: any,
  width?: number
}

const Popup: FC<Popup> = ({ children, showPopup, setShowPopup, width }) => {
  return (
      <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
          <div className={css.popupBG} onClick={() => setShowPopup(false)} />
          <div className={css.popupBody}>
              <div className={css.popupContent} style={{ width: width }}>
                {children}
              </div>
          </div>
      </div>
  )
}

export default Popup;