import { FC, useState } from "react";
import css from './index.module.css';
import Popup from "@components/popup";
import { toast } from "sonner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Modal {
  showModal: boolean; 
  setShowModal: any;
}

export const CreateTicket:FC<Modal> = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_URL}/support`, formData, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }})
      .then(async (response) => {
        navigate(`/support/${response.data.id}`)
        toast.success('Обращение создано');
        setShowModal(false);
      });
  };

  return (
      <Popup showPopup={showModal} setShowPopup={setShowModal}>
        <h5 style={{ fontSize: '20px', marginBottom: 10, marginTop: 0 }}>Создать обращение</h5>

        <form onSubmit={handleSubmit} className={css.form}>
          <select name="type">
            <option>Вопрос</option>
            <option>Проблема</option>
            <option>Квоты</option>
          </select>

          <input name="topic" placeholder="Тема обращения" required />
          <textarea name="msg" placeholder="Текст обращения..." />

          <button type="submit">Создать обращение</button>
        </form>
      </Popup>
  );
}
