import { FC } from "react";
import { Link } from "react-router-dom";
import css from './index.module.css';
import Header from "../header";
import Footer from "@components/footer";

interface MainContent {
  children: any;
  width?: number;
}

const MainContent:FC<MainContent> = ({ children, width }) => {
  return (
    <div className={css.sweetKit}>
      <Header />

      <div className={css.sweetKit_body}>
        <div className={css.sweetKit_body_contentnCover} style={{ maxWidth: width }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainContent;