import { useState, useEffect, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import Logo from '@assets/images/logo.svg';
import useUserData from "@hooks/useUserData";
import Popup from "@components/popup";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export default function Header() {
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [activePopup, setActivePopup] = useState(null); 
    const { userData, projects } = useUserData();

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const togglePopup = (popupName: any) => {
        setActivePopup(activePopup === popupName ? null : popupName);
    };

    return (
    <>
        <ChangeProject showModal={showModal} setShowModal={setShowModal} />
        <header className={`${css.header} ${isSticky ? css.sticky : ''}`}>
            <div className={css.body}>
                <div className={css.leftMenu}>
                    <img src={Logo} alt="Logo" className={css.logo} onClick={() => navigate('/')} />
                    
                    <div className={css.project} onClick={() => setShowModal(true)}>
                        <a style={{ fontSize: 16 }}>{projects?.find(i => i.id.toString() === userData?.project)?.name}</a>
                        <i className="fi fi-rr-angle-small-right"></i>
                    </div>

                    <div className={css.menu}>
                        <Link to={`/`}>Контейнеры</Link>
                        <Link to={`/support`}>Поддержка</Link>
                    </div>
                </div>

                <div className={css.rightMenu}>
                    <Link className={css.wallet} to={'/wallet'}>
                        <i className="fi fi-rr-wallet"></i>
                        <a>{userData?.balance} ₽</a>
                    </Link>
                    <Link to={`/account`}><img className={css.avatar} src={'https://avatars.mds.yandex.net/i?id=e3583305ecf833845bce0d64157b63b5_l-5220445-images-thumbs&n=13'} alt="avatar" /></Link>
                </div>
            </div>
        </header>
    </>
    )
}

interface Modal {
    showModal: boolean; 
    setShowModal: any;
}
  
export const ChangeProject:FC<Modal> = ({ showModal, setShowModal }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const { projects, refreshUserData, refreshProjectData } = useUserData();

    useEffect(() => {
        setPage(0);
    }, [showModal]);

    const handleChange = async (id: string) => {
      axios.post(`${import.meta.env.VITE_API_URL}/user/project`, { id: id }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }})
        .then(async (_) => {
            toast.success('Проект изменен');
            refreshUserData();
        });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        axios.post(`${import.meta.env.VITE_API_URL}/user/projects`, formData, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }})
          .then(async (_) => {
              toast.success('Проект создан');
              refreshProjectData();
          });
      };

    return (
        <Popup showPopup={showModal} setShowPopup={setShowModal}>
            {page === 0 ?
                <>
                    <h5 style={{ fontSize: '20px', marginBottom: 10, marginTop: 0 }}>Выберите проект</h5>

                    <div className={css.projects}>
                        {projects?.map(item => (
                            <div className={css.project} style={{ padding: '10px 15px' }} onClick={() => handleChange(item.id.toString())}>
                                <a style={{ fontSize: 16 }}>{item.name}</a>
                                <i className="fi fi-rr-angle-small-right"></i>
                            </div>
                        ))}

                        <div className={css.project} style={{ padding: '10px 15px' }} onClick={() => setPage(1)}>
                            <a style={{ fontSize: 16 }}>Добавить новый</a>
                            <i className="fi fi-rr-plus-small"></i>
                        </div>
                    </div>
                </>
            :
                <>
                    <h5 style={{ fontSize: '20px', marginBottom: 10, marginTop: 0 }}>Создать проект</h5>

                    <form className={css.form} onSubmit={handleSubmit}>
                        <input placeholder="Название проекта" name="name" required />
                        <button type="submit" style={{ width: '100%' }}>Продолжить</button>
                    </form>   
                </>
            }
        </Popup>
    );
  }
  