import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import css from './index.module.css';
import InputMask from 'react-input-mask';

import Logo from '@assets/images/logo.svg';
import VK from '@assets/images/vk.svg';
import YA from '@assets/images/ya.svg';
import LeeCyber from '@assets/images/leecyber.svg';
import SEO from "@components/seo";
import { toast } from "sonner";

export default function Authorization () {
  const [step, setStep] = useState<number>(0);
  const [uuid, setUuid] = useState<string>('');
  const [phone, setPhone] = useState('');

  const handlePhone = (event: any) => {
    setPhone(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('phone', phone);

    axios.post(`${import.meta.env.VITE_API_URL}/user/auth`, formData, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(_ => {
        setStep(1);
    })
    .catch(error => {
        console.log(error);
    });
  };

  const handleSubmitReg = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('phone', phone);
    formData.append('status', step.toString());
    formData.append('uuid', uuid);

    axios.post(`${import.meta.env.VITE_API_URL}/user/reg`, formData, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        localStorage.setItem('authToken', response.data.token);
        window.location.replace("/");
    })
    .catch(error => {
        console.log(error);
    });
  };

  return (
    <div className={css.container}>
        <SEO 
            title="Авторизация"
            description="Авторизация"
            ogUrl="https://sweetcatshop.ru/"
        />

        <div className={css.component}>
            <img src={Logo} alt="Logo" />
            
            {step === 0 &&
                <form className={css.form} onSubmit={handleSubmit}>
                    <a className={css.head}>Добро пожаловать!</a>
                    <InputMask placeholder="Введите номер телефона" mask="+7 (999) 999-99-99" value={phone} onChange={handlePhone} required />
                    <div className={css.btns}>
                        <button type="submit">Продолжить</button>
                        {/* <div className={css.services}>
                            <button type="submit" className={css.serviceBtn}><img src={YA} alt="yandex" className={css.service} style={{ width: 25 }}/></button>
                            <button type="submit" className={css.serviceBtn}><img src={LeeCyber} alt="yandex" className={css.service} style={{ width: 22 }}/></button>
                            <button type="submit" className={css.serviceBtn}><img src={VK} alt="vk" className={css.service} style={{ width: 22 }}/></button>
                        </div> */}
                    </div>
                    <a className={css.foot}>Нажимая кнопку “продолжить” я соглашаюсь с политикой обработки персональных данных</a>

                    <div className={css.pre}>
                        <h1>Функциональность ограничена</h1>
                        <a>Мы проводим тестирование портала, доступ по заявкам.</a>
                    </div>
                </form>   
            }

            {step === 1 &&
                <form className={css.form}>
                    <a className={css.head}>Вход в личный кабинет</a>
                    <SmsCode phone={phone} setStep={setStep} setUuid={setUuid} />
                    <button type="submit">Повторить отправку</button>
                    <a className={css.foot}>Нажимая кнопку “продолжить” я соглашаюсь с политикой обработки персональных данных</a>
                </form>
            }

            {step >= 2 &&
                <form className={css.form} onSubmit={handleSubmitReg}>
                    <a className={css.head}>Давайте познакомимся</a>

                    <input name="firstName" placeholder="Как Вас зовут?" />
                    {step === 2.1 && <input name="phone" placeholder="Ваш номер телефона" />}
                    <input name="projectName" placeholder="Название проекта, компании" />
                    <input name="email" placeholder="Ваша электронная почта" />
                    <input name="promocode" placeholder="Промокод (если есть)" />

                    <button type="submit">Сохранить изменения</button>
                    <a className={css.foot}>Нажимая кнопку “продолжить” я соглашаюсь с политикой обработки персональных данных</a>
                </form>   
            }

            <div className={css.legal}>
                <a>Условия сервиса</a>
                <a>Политика обработки данных</a>
            </div>
        </div>
    </div>
  )
}

function SmsCode({ phone, setStep, setUuid }: { phone: string, setStep: any, setUuid: any }) {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [codeEntered, setCodeEntered] = useState<boolean>(false);
    const inputRefs = useRef<any>([]);

    const handleChange = (e: any, index: number) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value.slice(0, 1);
            setCode(newCode);

            if (index < 5 && value !== '') {
                inputRefs.current[index + 1].focus();
            }

            if (newCode.every((digit) => digit !== '')) {
                setCodeEntered(true);
            } else {
                setCodeEntered(false);
            }
        }
    };

    const handleBackspace = (e: any, index: number) => {
        if (e.keyCode === 8 && index > 0 && code[index] === '') {
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            inputRefs.current[index - 1].focus();
            setCodeEntered(false);
        }
    };

    const renderInputs = () => {
        const inputs = [];
        for (let i = 0; i < 6; i++) {
            inputs.push(
                <input
                    key={i}
                    type="text"
                    maxLength={1}
                    value={code[i]}
                    onChange={(e) => handleChange(e, i)}
                    onKeyDown={(e) => handleBackspace(e, i)}
                    ref={(el) => (inputRefs.current[i] = el)}
                />
            );
        }
        return inputs;
    };

    const sendCode = () => {
        const enteredCode = code.join('');
        axios.post(`${import.meta.env.VITE_API_URL}/user/auth/code`, { phone: phone, code: enteredCode })
            .then(response => {
                if (response.data.status != 0) {
                    setUuid(response.data.id);
                    setStep(response.data.status);
                } else {
                    localStorage.setItem('authToken', response.data.token);
                    window.location.replace("/");
                }
            })
            .catch(() => {
                toast.error('Неверно введен код авторизации');
            });
    };

    useEffect(() => {
        if (codeEntered) {
            sendCode();
            setCodeEntered(false);
        }
    }, [codeEntered]);

    return (
        <div className={css.enterCode}>
            {renderInputs()}
        </div>
    );
}
