import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaChart, Area, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface Chart {
  data: any;
}

interface CustomTooltip {
  active?: any;
  payload?: any;
  label?: any;
}

export const CpuChart:FC<Chart> = ({ data }) => { 
    const { t } = useTranslation();

    return (
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={data} style={{ right: 20 }}>
          <h5 style={{ marginBottom: 15, fontSize: 17 }}>{t('gtm.cpu')}</h5>
          
          <CartesianGrid strokeDasharray="1" />
          <YAxis dataKey="load" />
          <Area type="monotone" dataKey="load" stroke="#8884d8" fill="#8884d8" />
          <Tooltip content={<CustomTooltip />}  />
        </AreaChart>
      </ResponsiveContainer>
    )
}

export const DiskChart:FC<Chart> = ({ data }) => { 
    const { t } = useTranslation();

    return (
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={data} style={{ right: 20 }}>  
          <h5 style={{ marginBottom: 15, fontSize: 17 }}>{t('gtm.disk')}</h5>
          <CartesianGrid strokeDasharray="1" />

          <YAxis dataKey="write" />
          <Tooltip content={<CustomTooltip />}  />
          <Area type="monotone" dataKey="write" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
    )
}

export const RamChart:FC<Chart> = ({ data }) => { 
    const { t } = useTranslation();

    return (
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={data} style={{ right: 20 }}> 
          <h5 style={{ marginBottom: 15, fontSize: 17 }}>{t('gtm.ram')}</h5> 
          <YAxis dataKey="used" />
          <Tooltip content={<CustomTooltip />}  />
          <Area type="monotone" dataKey="used_cached" stroke="#8ab265" fill="#8ab265" />
          <Area type="monotone" dataKey="used" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
    )
}

export const CustomTooltip:FC<CustomTooltip> = ({ active, payload }) => { 
    function convertToDate(isoDate: any) {
        const date = new Date(isoDate);
        const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Intl.DateTimeFormat('ru-Ru', options).format(date);
    }
    
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <a className="label">{`${convertToDate(payload[0].payload.logged_at)}`}</a><br />
          {payload[0].payload.used != undefined ?
          <>
            <a className="label" style={{ color: "var(--text-color)", fontWeight: 400 }}>Used cached {`${payload[0].payload.used_cached}`} mb</a><br />
            <a className="label" style={{ color: "var(--text-color)", fontWeight: 400 }}>Used {`${payload[0].payload.used}`} mb</a>
          </>
            :
            <a className="label" style={{ color: "var(--text-color)", fontWeight: 400 }}>{`${payload[0].payload.write != undefined ? payload[0].payload.write : payload[0].payload.load}`}%</a>
          }
        </div>
      );
    }
  
    return null;
};
