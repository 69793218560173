import { useState, useEffect, FC } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import Popup from "@components/popup";
import * as Types from '../../types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { gruvboxLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface Modal {
    showModal: boolean; 
    setShowModal: any;
    info?: Types.Container;
    setStep?: any;
}

export const DeleteContainer:FC<Modal> = ({ showModal, setShowModal }) => {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        axios.delete(`${import.meta.env.VITE_API_URL}/gtm/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
        }).then(_ => {       
            toast.success(t('gtm.deleted'));
            navigate(`/analytics/gtm/`);
        }).catch(_ => {
            toast.error(t('gtm.err'));
        });
    };
  
    return (
        <Popup showPopup={showModal} setShowPopup={setShowModal}>
            <h5 style={{ fontSize: '17px', marginBottom: 10, marginTop: 0 }}>{t('gtm.deleteHead')}</h5>
            <p style={{color: "var(--text-color)"}}>{t('gtm.deleteP')}</p>

            <form onSubmit={handleSubmit}>
                <button style={{ background: "#e52e2e", width: "100%" }} className="btn" type="submit">{t('gtm.deteleBtn')}</button>
            </form>
        </Popup>
    );
}

export const UpdateInformation:FC<Modal> = ({ showModal, setShowModal, info, setStep }) => {
    const { t } = useTranslation();
    let { id } = useParams();
    
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        axios.post(`${import.meta.env.VITE_API_URL}/gtm/${id}/update`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
        }).then(_ => {       
            setStep(3);
            toast.success(t('gtm.updatedServ'));
            setShowModal(false);
        }).catch(_ => {
            toast.error(t('gtm.err'));
        });
    };
  
    return (
        <Popup showPopup={showModal} setShowPopup={setShowModal}>
            <h5 style={{ fontSize: '17px', marginBottom: 10, marginTop: 0 }}>{t('gtm.update')}</h5>
            <p style={{color: "var(--text-color)"}}>{t('gtm.updatep')}</p>

            <form onSubmit={handleSubmit}>
                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>{t('gtm.name')}</label>
                    <input placeholder={t('gtm.name')} defaultValue={info?.name} name="name" />
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>{t('gtm.token')}</label>
                    <input placeholder={t('gtm.tokenExample')} defaultValue={info?.gtmToken} name="token" />
                </div>

                <button style={{ background: "#6258ee", width: '100%' }} className="btn" type="submit">{t('gtm.update')}</button>
            </form>
        </Popup>
    );
}

export const CodeInfo:FC<Modal> = ({ showModal, setShowModal, info }) => {
    const { t } = useTranslation();
    const [tag, setTag] = useState('GTM-ABCDEFGH');

    const closeModal = () => {
      setShowModal(false);
    };
  
    const codeString1 = 
`<!-- Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://${info?.domains.find(i => i.type === 0)?.value}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${tag}');</script>
<!-- End Tag Manager -->`;

    const codeString2 = 
`<!-- Tag Manager (noscript) -->
<noscript><iframe src="https://${info?.domains.find(i => i.type === 0)?.value}/ns.html?id=${tag}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Tag Manager (noscript) -->`;
    
    const copyToClipboard = (id: number) => {
        navigator.clipboard.writeText(id === 1 ? codeString1 : codeString2).then(() => {
            toast.success(t('gtm.copyed'));
        }).catch(_ => {
            toast.success(t('gtm.error'));
        });
    };

    return (
    <> 
        <Popup showPopup={showModal} setShowPopup={setShowModal} width={700}>
            <h5 style={{ fontSize: '17px', marginBottom: 10, marginTop: 0 }}>{t('gtm.installHead')}</h5>
            <p style={{color: "#000"}}>{t('gtm.installHeadp')}</p>

            <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                <label style={{fontSize: "14px",color: "#000", marginBottom: 0 }}>{t('gtm.contHTML')}</label>
                <input placeholder="GTM-ABCDEFGH" onChange={(event) => setTag(event.target.value)} name="token" />
            </div>

            <label style={{fontSize: "14px",color: "#000", marginBottom: 0 }}>{t('gtm.headHTML')}</label>
            <SyntaxHighlighter 
                language="javascript" 
                style={gruvboxLight}
                onClick={() => copyToClipboard(1)}
                customStyle={{
                borderRadius: '20px',
                fontSize: '16px',
                backgroundColor: 'rgb(0 0 0 / 7%)',
                border: "1px solid rgb(0 0 0 / 15%)",
                boxSizing: 'border-box',
                overflow: 'hidden',
                cursor: 'pointer'
            }}
            >
            {codeString1}
            </SyntaxHighlighter>

            <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>{t('gtm.bodyHTML')}</label>
            <SyntaxHighlighter 
                language="javascript" 
                style={gruvboxLight}
                onClick={() => copyToClipboard(2)}
                customStyle={{
                borderRadius: '20px',
                fontSize: '16px',
                backgroundColor: 'rgb(0 0 0 / 7%)',
                border: "1px solid rgb(0 0 0 / 15%)",
                boxSizing: 'border-box',
                overflow: 'hidden',
                cursor: 'pointer'
            }}
            >
            {codeString2}
            </SyntaxHighlighter>
        </Popup>
      </>
    );
}