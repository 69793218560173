import { FC, useState } from "react";
import css from './index.module.css';
import Popup from "@components/popup";
import { toast } from "sonner";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface Recurrent {
    event: string;
    value: number;
    updatedAt: string;
}

export const Recurrent:FC<Recurrent> = ({ event, value, updatedAt }) => {
  return (
    <div className={css.recurrent}>
        <div className={css.mainInfo}>
            <b>{event}</b>
            <a>был продлен {updatedAt}</a>
        </div>
        <div className={css.status}>
            <a>{value} ₽</a>
        </div>
    </div>
  );
};

interface History {
    type: number;
    status: number;
    event: string;
    value: number;
    createdAt: string;
}

export const History:FC<History> = ({ type, status, event, value, createdAt }) => {
  return (
    <div className={css.historyBlock}>
        <div className={css.mainInfo}>
            <b>{event}</b>
            <a>{createdAt} · {status}</a>
        </div>
        <div className={css.status}>
            <a>{type === 0 ? '-' : '+'} {value} ₽</a>
        </div>
    </div>
  );
};

interface Modal {
  showModal: boolean; 
  setShowModal: any;
}

export const AddBalance:FC<Modal> = ({ showModal, setShowModal }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(2500);

  const handlePayType = async (payType: string) => {
    if (value || value <= 10) {
      toast.error(t('org.balance.errSum'));
      return;
    }

    await toast.success(t('org.balance.createdDesc'));
    
    const formData = new FormData();
    formData.append('payType', payType);
    formData.append('amount', value.toString());

    axios.post(`${import.meta.env.VITE_API_URL}/pay/payment`, formData, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }})
      .then(async (response) => {
          const data = await response.data;
          if (data.url) {
              window.location.href = data.url;
          } else {
              console.error('URL not found in JSON response');
          }
      });
    };

  return (
      <Popup showPopup={showModal} setShowPopup={setShowModal}>
        <h5 style={{ fontSize: '20px', marginBottom: 10, marginTop: 0 }}>Пополнить кошелек</h5>

        <form>
          <input id="amountInput" value={value} onChange={(e) => setValue(parseInt(e.target.value))} type="number" placeholder="Сколько нужно пополнить?" required />
        </form>

        <div className={css.payTypes}>
          <button className={css.btnPay} style={{ justifyContent: 'inherit' }} onClick={() => handlePayType("bank_card")}><div>Банковской картой</div></button>
          <button className={css.btnPay} style={{ justifyContent: 'inherit' }} onClick={() => handlePayType("yoo_money")}><div>ЮMoney</div></button>
          <button className={css.btnPay} style={{ justifyContent: 'inherit' }} onClick={() => handlePayType("sberbank")}><div>SberPay</div></button>
          <button className={css.btnPay} style={{ justifyContent: 'inherit' }} onClick={() => handlePayType("tinkoff_bank")}><div>T-Pay</div></button>
          <button className={css.btnPay} style={{ justifyContent: 'inherit' }} onClick={() => handlePayType("sbp")}><div>СБП</div></button> 
        </div>
      </Popup>
  );
}
