import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import ContainerMini from "@components/containerMini";
import * as Types from '../../types';
import useUserData from "@hooks/useUserData";
import { toast } from "sonner";

import Logo from '@assets/images/logoMini.svg';

export default function Ticket() {
  let { id } = useParams();
  const { userData } = useUserData();
  const [ticket, setTicket] = useState<Types.Ticket | null>();
  const [updated, setUpdated] = useState<boolean>(true);

  useEffect(() => {
    if (updated === false) return;
    axios.get(`${import.meta.env.VITE_API_URL}/support/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
      })
      .then(response  => {
        setTicket(response.data);
        setUpdated(false);
      })
      .catch(_ => {
        setTicket(null);
    });
  }, [updated]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_URL}/support/${ticket?.id}`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }})
      .then(async (response) => {
        setUpdated(true);
        toast.success('Сообщение отправлено');
      });
  };

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }

  return (
    <MainContent width={750}>    
      <SEO 
        title="Главная"
        description="Магазин"
        ogUrl="https://sweetcatshop.ru/"
      />

      <div className={css.headTicket}>
        <Link to={`/support`} className={css.back}><i className="fi fi-rr-arrow-small-left"></i> Вернуться</Link>
        <h1>
          <div><a className={css.type}>{ticket?.type === 0 && "Вопрос"} {ticket?.type === 1 && "Проблема"} {ticket?.type === 2 && "Квоты"}</a> {ticket?.topic}</div>
          <a className={css.status}>{ticket?.status === 0 ? "На рассмотрении" : "Решили"}</a>
        </h1>
        <div>{ticket?.msg}</div>
      </div>

      <div className={css.msgs}>
        {ticket?.msgs.map(item => (
          <div className={css.msg}>
            {item.to === userData?.id.toString() ?
              <img alt="avatar" src={Logo}/>
            :
              <img alt="avatar" src="https://avatars.mds.yandex.net/i?id=e3583305ecf833845bce0d64157b63b5_l-5220445-images-thumbs&n=13" />
            }

            <div className={css.text}>
              <b>{item.to === userData?.id.toString() ? "Поддержка" : "Вы"} <a className={css.date}>{convertToDate(item.createdAt)}</a></b>
              <p>{item.msg}</p>
            </div>
          </div>
        ))}
      </div>
      
      <form className={css.form} onSubmit={handleSubmit}>
        <textarea name="msg" placeholder="Введите текст..."/>
        <button type="submit" style={{ width: "100%", background: "#0f51d4", marginTop: 10, padding: 10, color: "#fff" }}>Отправить сообщение</button>
      </form>
    </MainContent>
  )
}