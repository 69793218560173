import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import ContainerMini from "@components/containerMini";
import * as Types from '../../types';
import useUserData from "@hooks/useUserData";

export default function RootPage() {
  const [containers, setContainers] = useState<Types.Container[]>([]);
  const { userData } = useUserData();
  const navigate = useNavigate();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/gtm`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
      })
      .then(response  => {
        setContainers(response.data);
      })
      .catch(error => {
        setContainers([]);
    });
  }, [userData]);

  return (
    <MainContent>    
      <SEO 
        title="Главная"
        description="Магазин"
        ogUrl="https://sweetcatshop.ru/"
      />

      <div className={css.head}>
        <h1>Ваши контейнеры</h1>
        <Link to={'/container/create'}>Создать новый</Link>
      </div>

      <div className={css.containers}>
        {containers?.map(item => (
          <ContainerMini 
            uuid={item.id.toString()}
            name={item.name}
            domain={item.domains.find(i => i.type === 0)?.value!}
            status={item.status}
          />
        ))}

        {containers.length <= 0 && <a>Контейнеров нет.</a>}
      </div>
    </MainContent>
  )
}