import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";

import { useTranslation } from 'react-i18next';
import { toast } from "sonner";

export default function CreateContainer() {
    const { t } = useTranslation();
    let { id } = useParams();
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const [domain, setDomain] = useState<string>('');
    const [domainPre, setDomainPre] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isValidPre, setIsValidPre] = useState<boolean>(true);
    const [city, setCity] = useState<string>('ru-0');
    const [tariff, setTariff] = useState<number>(0);

    const domainPattern = /^(?!http:\/\/|https:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  
    const handleChangeDomain = (e: any) => {
      const value = e.target.value;
      setDomain(value);
  
      if (domainPattern.test(value)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    };

    const handleChangePre = (e: any) => {
        const value = e.target.value;
        setDomainPre(value);
    
        if (domainPattern.test(value)) {
            setIsValidPre(true);
        } else {
            setIsValidPre(false);
        }
    };

    function handleStep (event: any) {
        event.preventDefault();

        const formData = new FormData(event.target);
        formData.append('city', city);
        formData.append('tariff', tariff.toString());

        axios.post(`${import.meta.env.VITE_API_URL}/gtm/create`, formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }})
        .then(response => {
            toast.success(t('gtm.on'));
            event.target.reset();
            navigate(`/container/${response.data.uuid}`);
        }).catch(error => {
            toast.error(t('gtm.errFounds'));
        }) 
    }

    function calculatePrice () {
        let price = 2500;
        
        if (tariff === 0) price = 2500;
        if (tariff === 1) price = 5000;
        if (tariff === 2) price = 10000;

        if (city === 'nl-0') price += 300;
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  return (
    <MainContent>    
        <SEO 
            title="Создать контейнер"
            description="Магазин"
            ogUrl="https://sweetcatshop.ru/"
        />

        <div className={css.head}>
            <Link to={`/`} className={css.back}><i className="fi fi-rr-arrow-small-left"></i> Вернуться</Link>
            <h1>Создать контейнер</h1>
        </div>

        <div className={css.steps}>
            <div className={step == 1 ? css.stepActive : step > 1 ? css.stepSuccess : css.step}>
                <a>{t('gtm.step')} 1</a>
            </div>
            <div className={step == 2 ? css.stepActive : step > 2 ? css.stepSuccess : css.step}>
                <a>{t('gtm.step')} 2</a>
            </div>
            <div className={step == 3 ? css.stepActive : step > 3 ? css.stepSuccess : css.step}>
                <a>{t('gtm.step')} 3</a>
            </div>
        </div>        

        <form className={css.form} onSubmit={handleStep}>
            <div className={css.inputBlock}>
                <label style={{fontSize: "14px",color: "var(--text-color)",marginTop: "0px"}}>{t('gtm.name')}</label>
                <input placeholder={t('gtm.name')} name="name" required/>
            </div>

            <div className={css.inputBlock}>
                <label style={{fontSize: "14px",color: "var(--text-color)",marginTop: "0px"}}>{t('gtm.domain')}</label>
                <input placeholder={t('gtm.domainExample')} value={domain} onChange={handleChangeDomain}  name="domain" required/>
                {!isValid && ( <a style={{ color: '#ff9595', fontSize: 13, margin: 0, marginBottom: 10 }}>Неверный формат домена. Пример: gtm.leecyber.com (без http/https).</a> )}
            </div>

            <div className={css.inputBlock}>
                <label style={{fontSize: "14px",color: "var(--text-color)",marginTop: "0px"}}>{t('gtm.domainPre')}</label>
                <input placeholder={t('gtm.domainPreExample')} value={domainPre} onChange={handleChangePre}  name="domainPre" required/>
                {!isValidPre && ( <a style={{ color: '#ff9595', fontSize: 13, margin: 0, marginBottom: 10 }}>Неверный формат домена. Пример: gtm.leecyber.com (без http/https).</a> )}
            </div>
            
            <div className={css.inputBlock}>
                <label style={{fontSize: "14px",color: "var(--text-color)",marginTop: "0px"}}>{t('gtm.token')}</label>
                <input placeholder={t('gtm.tokenExample')} name="token" required/>
                <Link to={'https://leecyber.com/sgtm'} style={{fontSize: "14px",color: "var(--text-color)",marginTop: "5px", width: 'fit-content'}}>{t('gtm.whereToken')} →</Link>
            </div>

            <div className={css.inputBlock} style={{ marginTop: 5 }}>
                <label style={{fontSize: "14px",color: "var(--text-color)",marginTop: "0px"}}>{t('gtm.tariff')}</label>
                <div className={css.selector}>                                
                    <div className={`${tariff === 0 && css.active}`} onClick={() => setTariff(0)}>Базовый — 2 500 ₽/мес</div>
                    <div className={`${tariff === 1 && css.active}`} onClick={() => setTariff(1)}>Стандарт — 5 000 ₽/мес</div>
                    <div className={`${tariff === 2 && css.active}`} onClick={() => setTariff(2)}>Бизнес — 10 000 ₽/мес</div>
                    <div onClick={() => navigate('/support')}>Крупный бизнес — по запросу</div>
                </div>
                <Link to={'https://leecyber.com/sgtm'} style={{fontSize: "14px",color: "var(--text-color)",marginTop: "5px", width: 'fit-content'}}>{t('gtm.tariffs')} →</Link>
            </div>

            <div className={css.inputBlock}>
                <label style={{fontSize: "14px",color: "var(--text-color)",marginTop: "5px"}}>{t('gtm.city')}</label>
                <div className={css.selector}>                                
                    <div className={`${city === 'ru-0' && css.active}`} onClick={() => setCity('ru-0')}>Россия (Санкт-Петербург)</div>
                    <div className={`${city === 'ru-1' && css.active}`} onClick={() => setCity('ru-1')}>Россия (Москва)</div>
                    <div className={`${city === 'nl-0' && css.active}`} onClick={() => setCity('nl-0')}>Нидерланды (Амстердам)</div>
                </div>
            </div>

            <button type="submit" style={{ width: "100%", background: "#0f51d4", marginTop: 10, padding: 10, color: "#fff" }} disabled={!isValid}>{t('gtm.next')} — <b style={{ fontWeight: 600 }}>{calculatePrice()} ₽/мес</b></button>
        </form>
    </MainContent>
  )
}