import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import SupportMini from "@components/supportMini";
import { CreateTicket } from './modal';

import * as Types from '../../types';

export default function Support() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tickets, setTickets] = useState<Types.Ticket[]>([]);
        
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/support`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
      })
      .then(response  => {
        setTickets(response.data);
      })
      .catch(error => {
        setTickets([]);
    });
  }, []);

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }
  
  return (
    <MainContent>    
      <CreateTicket showModal={showModal} setShowModal={setShowModal} />
      <SEO 
        title="Главная"
        description="Магазин"
        ogUrl="https://sweetcatshop.ru/"
      />

      <div className={css.head}>
        <h1>Поддержка</h1>
        <a onClick={() => setShowModal(true)}>Создать обращение</a>
      </div>

      <div className={css.containers}>
        {tickets.map(item => (
          <SupportMini 
            uuid={item.id.toString()}
            name={item.topic}
            domain={convertToDate(item.createdAt)}
            status={item.status}
          />
        ))}

        {tickets.length <= 0 && <a>Обращений нет.</a>}
      </div>
    </MainContent>
  )
}