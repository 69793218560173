import '@locales/index';
import useUserData from '@hooks/useUserData';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import { Toaster } from 'sonner';
import NotFound from '@pages/system/notFound';
import Authorization from '@pages/auth';
import Container from '@pages/container';

import RootPage from '@pages/home';
import CreateContainer from '@pages/container/create';
import Wallet from '@pages/wallet';
import Account from '@pages/account';
import Support from '@pages/support';
import Ticket from '@pages/support/ticket';

function App() {
  const { userData } = useUserData();

  return (
    <Router basename="/">
      <Toaster position="bottom-right" richColors expand={false} />
      <ScrollToTop />
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={userData ? <RootPage /> : <Authorization />} />
        <Route path='/auth' element={<Authorization />} />
        <Route path='/container/create' element={userData ? <CreateContainer /> : <Authorization />} />
        <Route path='/container/:id' element={userData ? <Container /> : <Authorization />} />
        <Route path='/wallet' element={userData ? <Wallet /> : <Authorization />} />
        <Route path='/account' element={userData ? <Account /> : <Authorization />} />
        <Route path='/support' element={userData ? <Support /> : <Authorization />} />
        <Route path='/support/:id' element={userData ? <Ticket /> : <Authorization />} />

        {userData && userData.access > 0 &&
          <>
            1
          </>
        }
      </Routes>
    </Router>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App
