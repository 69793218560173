import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsInRus from './ru/translation.json';

const resources = {
  ru: {
    translation: translationsInRus
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ru",
    debug: false,
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false
    },
    ns: "translation",
    defaultNS: "translation"
  });

export default i18n;